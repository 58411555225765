<script setup lang="ts">
import '@/assets/styles/index.postcss'
const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true
})

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
    dir: i18nHead.value.htmlAttrs!.dir,
  },
  script: [
    {
      src: 'https://www.googletagmanager.com/gtag/js?id=AW-11230515887',
      async: true,
    },
    {
      innerHTML: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-11230515887'); gtag('config', 'G-0BWZBMJXNC');"
    }
  ]
})
useSchemaOrg([
  defineOrganization({
    name: 'WorkSnap',
    logo: '/ws-logo.png',
    sameAs: [
      'https://www.facebook.com/profile.php?id=100085010487813',
      'https://www.instagram.com/worksnap_meetsnap/'
    ]
  }),
  defineWebSite(),
  defineWebPage(),
])
</script>

<template>
  <NuxtLayout>
    <SeoKit :language="i18nHead.htmlAttrs!.lang"/>
    <OgImageStatic />
    <NuxtPage/>
  </NuxtLayout>
</template>